import { CSSObject } from '@chakra-ui/react';
import featuredMatch from '@/assets/chromabet/images/bg-sports-featured-match.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  border: '1px solid',
  borderColor: 'rgba(255, 255, 255, 0.11)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  bgImage: `url(${featuredMatch})`,
  bgSize: 'cover',
  bgPos: 'bottom',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  fontFamily: 'accent',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
};

export const HeadingSlogan: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'beta.500',
  boxShadow: '0px 0px 8px 0px rgba(125, 253, 42, 0.7)',
  border: '1px solid',
  borderColor: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'bold',
  borderRadius: 'md',
};

export const TextMisc: CSSObject = {
  bg: 'beta.500',
  boxShadow: '0px 0px 8px 0px rgba(125, 253, 42, 0.7)',
  borderRadius: 'base',
  color: 'alpha.500',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontSize: '2xl',
  fontWeight: 'bold',
  color: 'beta.300',
  textTransform: 'uppercase',
};

export const puntButtonProps: CSSObject = {
  fontSize: 'xs',
  textTransform: 'uppercase',
  color: 'beta.400',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  h: '9',
  _after: {
    opacity: 0,
  },
};
