import * as betprofessor from './DatePicker.styles.betprofessor';
import * as goldenrush from './DatePicker.styles.goldenrush';
import * as juicybet from './DatePicker.styles.juicybet';
import * as puntcity from './DatePicker.styles.puntcity';
import * as sterlingparker from './DatePicker.styles.sterlingparker';
import * as vikingbet from './DatePicker.styles.vikingbet';
import * as betroyale from './DatePicker.styles.betroyale';
import * as junglebet from './DatePicker.styles.junglebet';
import * as sugarcastle from './DatePicker.styles.sugarcastle';
import * as buffalobet from './DatePicker.styles.buffalobet';
import * as oldgill from './DatePicker.styles.oldgill';
import * as gigabet from './DatePicker.styles.gigabet';
import * as slambet from './DatePicker.styles.slambet';
import * as luckystride from './DatePicker.styles.luckystride';
import * as templebet from './DatePicker.styles.templebet';
import * as goldenbet888 from './DatePicker.styles.goldenbet888';
import * as chromabet from './DatePicker.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  goldenrush,
  juicybet,
  puntcity,
  sterlingparker,
  vikingbet,
  betroyale,
  junglebet,
  sugarcastle,
  buffalobet,
  oldgill,
  gigabet,
  slambet,
  luckystride,
  templebet,
  goldenbet888,
  chromabet,
};
