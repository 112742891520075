import * as betgalaxy from './OddsChangedBanner.styles.betgalaxy';
import * as betprofessor from './OddsChangedBanner.styles.betprofessor';
import * as betroyale from './OddsChangedBanner.styles.betroyale';
import * as puntcity from './OddsChangedBanner.styles.puntcity';
import * as puntgenie from './OddsChangedBanner.styles.puntgenie';
import * as questbet from './OddsChangedBanner.styles.questbet';
import * as sterlingparker from './OddsChangedBanner.styles.sterlingparker';
import * as volcanobet from './OddsChangedBanner.styles.volcanobet';
import * as wellbet from './OddsChangedBanner.styles.wellbet';
import * as goldenrush from './OddsChangedBanner.styles.goldenrush';
import * as forsport from './OddsChangedBanner.styles.forsport';
import * as juicybet from './OddsChangedBanner.styles.juicybet';
import * as fiestabet from './OddsChangedBanner.styles.fiestabet';
import * as junglebet from './OddsChangedBanner.styles.junglebet';
import * as vikingbet from './OddsChangedBanner.styles.vikingbet';
import * as oldgill from './OddsChangedBanner.styles.oldgill';
import * as sugarcastle from './OddsChangedBanner.styles.sugarcastle';
import * as buffalobet from './OddsChangedBanner.styles.buffalobet';
import * as slambet from './OddsChangedBanner.styles.slambet';
import * as gigabet from './OddsChangedBanner.styles.gigabet';
import * as luckystride from './OddsChangedBanner.styles.luckystride';
import * as chromabet from './OddsChangedBanner.styles.chromabet';
import * as templebet from './OddsChangedBanner.styles.templebet';
import * as goldenbet888 from './OddsChangedBanner.styles.goldenbet888';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  volcanobet,
  wellbet,
  goldenrush,
  forsport,
  juicybet,
  fiestabet,
  junglebet,
  vikingbet,
  oldgill,
  sugarcastle,
  buffalobet,
  slambet,
  gigabet,
  luckystride,
  chromabet,
  templebet,
  goldenbet888,
};
