import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  bgGradient: 'linear(to-b, alpha.800, alpha.900)',
  borderRadius: 'md',
  boxShadow: '0px 2px 2px 0px rgba(125, 253, 42, 0.35) inset',
  boxSize: '10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'beta.500',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  borderRadius: 'md',
  w: '100%',
  h: '64px',
  _hover: {
    bg: 'alpha.500',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'md',
  color: 'white',
  mt: '1.5',
  fontFamily: 'accent',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'whiteAlpha.500',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'whiteAlpha.500',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'whit',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'whiteAlpha.500',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'white',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
