import { CSSObject } from '@chakra-ui/react';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    borderRadius: 'base',
    w: 12,
    fontSize: 'xs',
    minH: '34px',
    lineHeight: 'normal',
  },
  svgMoreRacesArrowProps: {
    color: 'white',
    boxSize: '5',
  },
};

export const BoxCard: CSSObject = {
  p: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  fontFamily: 'accent',
  bg: 'rgba(54, 54, 54)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(255, 255, 255, 0.11)',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'md',
  minH: '200px',
  py: '4',
  px: '3',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  // textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['1', null, '20px'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: 'start',
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'beta.300',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: 'xs',
  color: 'beta.400',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  bg: 'gray.200',
  borderRadius: '3px',
  color: 'gray.600',
  px: '1',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '42px',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.11))',
  borderRadius: 'base',
  border: '1px solid rgba(255, 255, 255, 0.11)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const LinkFeatureCard: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
