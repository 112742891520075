import { CSSObject } from '@chakra-ui/react';

export const GridLayout: CSSObject = {
  rowGap: '0',
};

export const GridItemContent: CSSObject = {
  mt: '1',
};

export const GridItemFooter: CSSObject = {
  mt: ['4', null, null, '0'],
};

export const GridSubLayout: CSSObject = {
  gridTemplateColumns: ['1fr', null, null, '270px 1fr', '270px 1fr 272px'],
  gridTemplateRows: [
    'auto auto 1fr',
    null,
    null,
    'auto auto 1fr auto',
    'auto 1fr auto',
  ],
  columnGap: ['2.5', null, null, '4'],
  gridTemplateAreas: [
    `
        "betslip"
        "sidenav"
        "content"
        "footer"
      `,
    null,
    null,
    `
        "sidenav betslip"
        "sidenav content"
        "sidenav content"
        "sidenav footer"
      `,

    `
        "sidenav content betslip"
        "sidenav content betslip"
        "sidenav footer betslip"
      `,
  ],

  'body[data-sensitive="true"] &': {
    gridTemplateRows: '',
    gridTemplateColumns: '',
    gridTemplateAreas: '',
  },
};

export const GridHeader: CSSObject = {
  boxShadow: 'lg',
  mb: ['0', '3'],
  maxW: '100%',
};

export const GridItemBetslip: CSSObject = {
  position: [null, null, null, null, 'sticky'],
  top: [null, null, null, null, '60px'],
  height: [null, null, null, null, 'calc(100vh - 120px)'],
  bg: 'transparent',
  pt: '1',
};

export const GridItemToggle: CSSObject = {
  position: [null, null, 'sticky'],
  top: [null, null, null, null, '60px'],
  width: [null, null, '270px'],
};

export const GridItemSideNav: CSSObject = {
  h: [null, null, null, null, 'calc(130vh)'],
  top: [null, null, null, null, '0'],
  bg: 'rgba(54, 54, 54, 0.9)',
  px: '12px',
  borderRadius: 'md',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 2px 12px 0px rgba(0, 0, 0, 0.45)',
};

export const GridItemResponsibleGambling: CSSObject = {
  mt: '0',
};
