import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import IconSvg, { TIconSvg } from '../../../../components/common/IconSvg';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    fontFamily: 'accent',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2px',
    mx: ['1.5', null, 'unset'],
    mb: ['1', null, '4'],
    bg: 'rgba(0, 0, 0, 0.4)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.12), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
    h: '42px',
    pos: 'relative',
  }),
});

const ButtonToggle = chakra(Button, {
  label: 'desktopToggle-ButtonToggle',
  shouldForwardProp: (prop) => !['isActive', 'category'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    w: '122px',
    h: '38px',
    borderRadius: '3px',
    fontWeight: 'bold',
    fontSize: 'normal',
    textTransform: 'uppercase',
    border: isActive ? '1px solid rgba(255, 255, 255, 0.10)' : 'none',
    letterSpacing: 'wide',
    textShadow: isActive ? '0px 0px 12px rgba(13, 252, 255, 1)' : '',
    bg: isActive
      ? 'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))'
      : 'transparent',
    color: isActive ? 'beta.300' : 'whiteAlpha.500',
    _hover: {
      bg: isActive
        ? 'linear(to-b, rgba(255, 255, 255, 0.17), rgba(255, 255, 255, 0.12))'
        : 'transparent',
    },
  }),
}) as ChakraComponent<
  'div',
  ButtonProps & { isActive: boolean; category: string }
>;

const TextMessage = chakra(FormattedMessage, {
  label: 'desktopToggle-ButtonToggle',
  shouldForwardProp: (prop) => !['isActive', 'category'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    filter: isActive
      ? 'drop-shadow(0px 0px 12px 0px rgba(13, 252, 255, 1)'
      : '',
  }),
});

export const Icon = chakra(IconSvg, {
  label: 'sportsList-icon-sport',
  baseStyle: () => ({
    boxSize: '5',
    mr: '2',
  }),
}) as ChakraComponent<'svg', TIconSvg & { isActive: boolean }>;

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;
  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }
  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }
  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  const toggleCategory = (newCategory: string) => {
    setSearchParams({ category: newCategory });
  };

  return (
    <FlexWrapper>
      <ButtonToggle
        type="button"
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
        onClick={() => toggleCategory('racing')}
        category="racing"
      >
        <TextMessage id="racing" defaultMessage="Racing" />
      </ButtonToggle>
      <ButtonToggle
        type="button"
        data-cy="sports-toggle-text"
        isActive={category === 'sport'}
        onClick={() => toggleCategory('sport')}
        category="sport"
      >
        <TextMessage id="sport" defaultMessage="Sport" />
      </ButtonToggle>
    </FlexWrapper>
  );
}
