import * as betprofessor from './LifetimeExclusion.styles.betprofessor';
import * as betroyale from './LifetimeExclusion.styles.betroyale';
import * as goldenrush from './LifetimeExclusion.styles.goldenrush';
import * as juicybet from './LifetimeExclusion.styles.juicybet';
import * as puntcity from './LifetimeExclusion.styles.puntcity';
import * as puntgenie from './LifetimeExclusion.styles.puntgenie';
import * as questbet from './LifetimeExclusion.styles.questbet';
import * as sterlingparker from './LifetimeExclusion.styles.sterlingparker';
import * as vikingbet from './LifetimeExclusion.styles.vikingbet';
import * as volcanobet from './LifetimeExclusion.styles.volcanobet';
import * as junglebet from './LifetimeExclusion.styles.junglebet';
import * as fiestabet from './LifetimeExclusion.styles.fiestabet';
import * as oldgill from './LifetimeExclusion.styles.oldgill';
import * as sugarcastle from './LifetimeExclusion.styles.sugarcastle';
import * as buffalobet from './LifetimeExclusion.styles.buffalobet';
import * as templebet from './LifetimeExclusion.styles.templebet';
import * as goldenbet888 from './LifetimeExclusion.styles.goldenbet888';
import * as chromabet from './LifetimeExclusion.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  junglebet,
  fiestabet,
  oldgill,
  sugarcastle,
  buffalobet,
  templebet,
  goldenbet888,
  chromabet,
};
