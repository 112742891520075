import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  fontFamily: 'accent',
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'whiteAlpha.400',
  borderRadius: 'sm',
  color: 'white',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const LinkRaceContainer: CSSObject = {
  bg: 'rgba(54, 54, 54, 0.7)',

  color: 'white',
  borderRadius: 'md',
  border: '1px solid rgba(255, 255, 255, 0.05)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  mb: '2.5',
  transition: 'all .2s ease-in-out',
  svg: {
    color: 'beta.400',
    filter: [
      'drop-shadow(0px 0px 8px rgba(125, 253, 42, 0.7))',
      null,
      null,
      'drop-shadow(0px 0px 4px rgba(37, 254, 214, 0.6))',
    ],
  },
  _hover: {
    color: 'beta.300',
    bg: 'alpha.600',
    // Change color for TextRace, Icon
    'p:first-of-type': {
      color: 'beta.300',
    },
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
  fontSize: 'xs',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.100',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
