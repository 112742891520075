import * as betprofessor from './PrimaryWrapper.styles.betprofessor';
import * as betroyale from './PrimaryWrapper.styles.betroyale';
import * as fiestabet from './PrimaryWrapper.styles.fiestabet';
import * as puntcity from './PrimaryWrapper.styles.puntcity';
import * as puntgenie from './PrimaryWrapper.styles.puntgenie';
import * as questbet from './PrimaryWrapper.styles.questbet';
import * as sterlingparker from './PrimaryWrapper.styles.sterlingparker';
import * as vikingbet from './PrimaryWrapper.styles.vikingbet';
import * as volcanobet from './PrimaryWrapper.styles.volcanobet';
import * as wellbet from './PrimaryWrapper.styles.wellbet';
import * as juicybet from './PrimaryWrapper.styles.juicybet';
import * as goldenrush from './PrimaryWrapper.styles.goldenrush';
import * as forsport from './PrimaryWrapper.styles.forsport';
import * as junglebet from './PrimaryWrapper.styles.junglebet';
import * as betgalaxy from './PrimaryWrapper.styles.betgalaxy';
import * as sugarcastle from './PrimaryWrapper.styles.sugarcastle';
import * as oldgill from './PrimaryWrapper.styles.oldgill';
import * as buffalobet from './PrimaryWrapper.styles.buffalobet';
import * as templebet from './PrimaryWrapper.styles.templebet';
import * as slambet from './PrimaryWrapper.styles.slambet';
import * as gigabet from './PrimaryWrapper.styles.gigabet';
import * as goldenbet888 from './PrimaryWrapper.styles.goldenbet888';
import * as chromabet from './PrimaryWrapper.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  juicybet,
  goldenrush,
  forsport,
  junglebet,
  betgalaxy,
  oldgill,
  sugarcastle,
  buffalobet,
  templebet,
  slambet,
  gigabet,
  goldenbet888,
  chromabet,
};
