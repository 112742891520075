import * as sterlingparker from './Matches.styles.sterlingparker';
import * as goldenrush from './Matches.styles.goldenrush';
import * as betroyale from './Matches.styles.betroyale';
import * as puntcity from './Matches.styles.puntcity';
import * as betgalaxy from './Matches.styles.betgalaxy';
import * as juicybet from './Matches.styles.juicybet';
import * as questbet from './Matches.styles.questbet';
import * as wellbet from './Matches.styles.wellbet';
import * as sugarcastle from './Matches.styles.sugarcastle';
import * as oldgill from './Matches.styles.oldgill';
import * as buffalobet from './Matches.styles.buffalobet';
import * as gigabet from './Matches.styles.gigabet';
import * as slambet from './Matches.styles.slambet';
import * as templebet from './Matches.styles.templebet';
import * as goldenbet888 from './Matches.styles.goldenbet888';
import * as chromabet from './Matches.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  sterlingparker,
  goldenrush,
  betroyale,
  puntcity,
  betgalaxy,
  juicybet,
  questbet,
  wellbet,
  sugarcastle,
  oldgill,
  buffalobet,
  gigabet,
  slambet,
  templebet,
  goldenbet888,
  chromabet,
};
