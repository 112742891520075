import * as betprofessor from './CompetitionsBySport.styles.betprofessor';
import * as betroyale from './CompetitionsBySport.styles.betroyale';
import * as fiestabet from './CompetitionsBySport.styles.fiestabet';
import * as puntcity from './CompetitionsBySport.styles.puntcity';
import * as puntgenie from './CompetitionsBySport.styles.puntgenie';
import * as questbet from './CompetitionsBySport.styles.questbet';
import * as sterlingparker from './CompetitionsBySport.styles.sterlingparker';
import * as vikingbet from './CompetitionsBySport.styles.vikingbet';
import * as volcanobet from './CompetitionsBySport.styles.volcanobet';
import * as wellbet from './CompetitionsBySport.styles.wellbet';
import * as goldenrush from './CompetitionsBySport.styles.goldenrush';
import * as juicybet from './CompetitionsBySport.styles.juicybet';
import * as forsport from './CompetitionsBySport.styles.forsport';
import * as junglebet from './CompetitionsBySport.styles.junglebet';
import * as betgalaxy from './CompetitionsBySport.styles.betgalaxy';
import * as sugarcastle from './CompetitionsBySport.styles.sugarcastle';
import * as oldgill from './CompetitionsBySport.styles.oldgill';
import * as buffalobet from './CompetitionsBySport.styles.buffalobet';
import * as gigabet from './CompetitionsBySport.styles.gigabet';
import * as slambet from './CompetitionsBySport.styles.slambet';
import * as templebet from './CompetitionsBySport.styles.templebet';
import * as goldenbet888 from './CompetitionsBySport.styles.goldenbet888';
import * as chromabet from './CompetitionsBySport.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  goldenrush,
  juicybet,
  forsport,
  junglebet,
  betgalaxy,
  sugarcastle,
  oldgill,
  buffalobet,
  gigabet,
  slambet,
  templebet,
  goldenbet888,
  chromabet,
};
