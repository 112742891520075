import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'lg',
    my: 2,
  },
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    mt: '-2',
  },
  promotionWrapper: {
    my: '3',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    borderRadius: 'md',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
      '.chakra-button': {
        borderRadius: 'md',
        border: 'none',
        bgGradient: 'linear(to-b, beta.200, beta.500)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: 'alpha.500',
        fontFamily: 'ProximaNova',
        fontSize: 'sm',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        _hover: {
          bgGradient: 'linear(to-b, beta.600, beta.600)',
        },
        ':active, &[data-active=true]': {
          bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
          boxShadow:
            '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
          color: 'beta.400',
          textShadow: '0px 0px 8px rgba(125, 253, 42, 0.7)',
        },
        _disabled: {
          opacity: 0.4,
          pointerEvents: 'none',
        },
        '&&&': {
          '& > svg, & > span': { zIndex: 9 },
        },
      },
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    borderTop: '3px solid',
    borderColor: 'beta.500',
    mx: '1px',
  },
  termsAndConditions: {
    color: 'white',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
